
     const Clientdata = [{
        "id":"I01",
        "name":"John Doe",
        "image":"https://res.cloudinary.com/diqqf3eq2/image/upload/v1586883334/person-1_rfzshl.jpg",
        "review":"Good product , connectivity is awosme ..we have booked 2BHK . Staff is really well they treat customer like king"
    },{
          "id":"I02",
        "name":"John Richard",
        "image":"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQPvd1u_iWqjqy3YVnwbd_q8Cbq88E-oOY1vw&usqp=CAU",
        "review":"An Assistant Professor at Qec, Imam Abdulrahman bin Faisal University"
    },{
         "id":"I02",
        "name":"Aden joe",
        "image":"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSlgPLahsT5_pnwy7llv0ZalWA2bEguJUSczQ&usqp=CAU",
        "review":"An Assistant Professor at Qec, Imam Abdulrahman bin Faisal University" 
    }]
    export default Clientdata